/* Input hide arrows Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Input hide arrows  Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.bold {
  font-weight: 700;
}

.height100 {
  height: 100%;
}

.active-link {
  border-left: 8px solid var(--ion-color-secondary);
}

.active-item {
  height: 4px;
  background-color: var(--ion-color-success);
}

.progress-wrapper,
.regular-item {
  height: 4px;
}

.input-error {
  font-size: 12px;
  padding-left: 16px;
}

.logo {
  &_signin {
    width: 100%;
    margin: auto;
    display: block;
  }
}

.choose-players {
  &__list {
    margin-bottom: 20px !important;
  }

  &__btn-block {
    margin-bottom: 20px;
  }
}

.players-list {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  &__info {
    flex: 1 1 100%;
  }
  &__color {
    flex: 0 0 49px;
    padding: 10px;
  }
}

.players-list-info {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  &__item {
    margin: 0 8px;
    &_name {
      flex: 1 1 100%;
    }
    &_btn {
    }
    &_code {
    }
  }
}

.select-color {
  border: 0;
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.select-color {
  display: flex;
  flex-flow: row nowrap;
  &__item {
    flex: 0 1 auto;
    width: 40px;
    height: 40px;
    margin: 0 15px;
    cursor: pointer;
    border-radius: 3px;
  }
  &__container {
    display: flex;
    justify-content: center;
  }
}

.round-subtitle {
  display: flex;
  align-items: center;
  &__icon {
    padding-right: 10px;
    min-width: 20px;
    // vertical-align: middle;
  }
  &__text {
    white-space: nowrap;
  }
  &__blank {
    flex: 1 1 100%;
  }
  &__score {
  }
}

.active-player {
  color: red;
  font-weight: 800;
}

.uno-img {
  padding: 0.25rem 0.5rem;
}

.uno-img:hover + .close-icon-popup {
  opacity: 0.8;
}

.close-icon-popup:hover {
  opacity: 0.8;
}

.close-icon-popup {
  transition: opacity 0.5s ease-in;
  opacity: 0;
  position: absolute;
  width: 64px;
  height: 64px;
  top: calc(50% - 32px);
  right: calc(50% - 32px);
  color: var(--ion-color-dark);
}

.round {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  ion-chip {
    // flex: 1 1 25%;
    display: flex;
    justify-content: center;
  }
  &_tools {
    margin-top: 15px;
    margin-bottom: 20px;
    min-height: 40px;
    &__score {
      flex: 1 1 30%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__btn {
      flex: 1 1 70%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &_result {
    justify-content: center;
    min-height: 48px;
  }
}

.incDecBtn {
  display: flex;
  flex-flow: row nowrap;
  &__caption {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }
  &__caption ion-text {
    font-size: 1.2rem;
  }
}

.thousand-score-input {
  padding: 0 10px;
}

.thousand-score {
  &__current {
    font-size: 0.8rem;
  }
  &__total {
    font-weight: 600;
    font-size: 0.8rem;
    &_last {
      font-size: 0.8rem;
    }
  }
  @media (min-width: 576px) {
    &__wrapper {
      // margin: 0 10px;
    }

    &__total {
      font-weight: 600;
      font-size: 1.1rem;
      &_last {
        font-size: 1.3rem;
      }
    }
  }
}

.round-btn-block {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 5px;
  &__item {
    // flex: 1 1 auto;
  }
}

.finish-game-modal {
  display: flex;
  height: 100%;
  align-items: stretch;
  flex-direction: column;
  &__text {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
  }
  &__buttons {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }
}

.card {
  max-width: 100%;
  border-radius: 4px;
  position: relative;
  img {
    width: 100%;
    border-radius: 4px;
    display: block;
  }
  &__content {
    background-color: rgba(1, 1, 1, 0.25);
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgb(255, 255, 255);
    padding: 16px 16px 8px;
  }
  &__title {
    font-size: 2rem;
    padding: 0 0 0 16px;
  }
  &__description {
    margin-bottom: 16px;
    text-align: justify;
    font-size: 13.5px;
    line-height: 1.3;
  }
  &__footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  &__btn {
    color: white;
    background-color: rgba(1, 1, 1, 0.3);
    border: 1px solid white;
    border-radius: 64px;
    padding: 4px 16px;
    font-size: 14px;
    text-transform: uppercase;
    text-wrap: nowrap;
    &:hover {
      background-color: rgba(1, 1, 1, 0.7);
    }
  }
}

.flex-rest-space {
  flex: 1;
}
